<template>
  <div>
    <Transition name="fade">
      <modal-devices v-if="app.state.matches('app.modal.visible.devices')" />
    </Transition>
    <Transition name="fade">
      <modal-permissions v-if="app.state.matches('app.modal.visible.permissions')" />
    </Transition>
    <Transition name="fade">
      <modal-qr v-if="app.state.matches('app.modal.visible.qr')" />
    </Transition>
    <Transition name="fade">
      <modal-connections v-if="app.state.matches('app.modal.visible.connections')" />
    </Transition>
    <Transition name="fade">
      <a-loader v-if="app.isLoading" :message="app.loadingMessage" @cancel="loaderCancelHandler" />
    </Transition>
    <permissions-view v-if="app.state.matches('app.view.permissions')" />
    <join-view v-if="app.state.matches('app.view.join')" />
    <meeting-view v-if="app.state.matches('app.view.meeting')" />
    <exit-view v-if="app.state.matches('app.view.exit')" />
  </div>
</template>
<style lang="scss">
html, body {
  overscroll-behavior: none;
  -webkit-overflow-behavior: none;
}
</style>
<script setup>
import container from '@di'
import { useAppStore } from '@/stores'
import { useLanguage } from '@/composables'
import process from 'process'

import {
  BackendNotificationProcedure,
  BoardProcedure,
  ChatProcedure,
  ProfileProcedure,
  StreamProcedure,
  StartSessionProcedure,
  ParticipantJoinedProcedure,
  ParticipantDisjoinedProcedure,
  ParticipantDisconnectedProcedure,
  ParticipantMuteProcedure,
  PermissionsProcedure,
  ParticipantReadyProcedure,
  PeerErrorProcedure,
  RoleExchangeProcedure,
  TransformProcedure,
  SnapshotProcedure,
  TorchProcedure,
  ConnectionsProcedure,
  ScreensharingProcedure,
  RecordingProcedure,
  DestroyProcedure,
  DocumentEventProcedure,
  NotificationProcedure,
  SignalProcedure,
  MuteUnmuteProcedure,
  ChangeStrategyProcedure,
  ConnectionFailedProcedure
} from './procedures'

const app = useAppStore()

window.process = process

const setupProcedures = () => {
  container.procedures.add(new BackendNotificationProcedure())
  container.procedures.add(new BoardProcedure())
  container.procedures.add(new ChatProcedure())
  container.procedures.add(new ConnectionFailedProcedure())
  !!window.localStorage.getItem('airelink:logger') && container.procedures.add(new ConnectionsProcedure())
  container.procedures.add(new ParticipantDisjoinedProcedure())
  container.procedures.add(new ParticipantJoinedProcedure())
  container.procedures.add(new StreamProcedure())
  container.procedures.add(new ProfileProcedure())
  container.procedures.add(new ParticipantDisconnectedProcedure())
  container.procedures.add(new ParticipantMuteProcedure())
  container.procedures.add(new PermissionsProcedure())
  container.procedures.add(new ParticipantReadyProcedure())
  container.procedures.add(new PeerErrorProcedure())
  container.procedures.add(new RecordingProcedure())
  container.procedures.add(new RoleExchangeProcedure())
  container.procedures.add(new TransformProcedure())
  container.procedures.add(new SnapshotProcedure())
  container.procedures.add(new TorchProcedure())
  container.procedures.add(new StartSessionProcedure())
  container.procedures.add(new ScreensharingProcedure())
  container.procedures.add(new DestroyProcedure())
  container.procedures.add(new DocumentEventProcedure())
  container.procedures.add(new NotificationProcedure())
  container.procedures.add(new SignalProcedure())
  container.procedures.add(new MuteUnmuteProcedure())
  container.procedures.add(new ChangeStrategyProcedure())
}

const { language, initialize } = useLanguage()
setupProcedures()
initialize(language.value).then(() => {
  app.transition('connected')
  app.transition('app')
})

function loaderCancelHandler () {
  // TODO: is it safe?
  app.loaded()
}
</script>
