<template>
  <div class="container">
  <a-button-box class="indicator px--2">
    <div class="indicator-inner">
      {{ session.time }}
      <a-divider class="ms--2 me--1" />
      <a-signal
        v-if="signal.isAvailable"
        :level="signal.level"
      />
      <div v-if="signal.warning" class="pte--auto">
        <meeting-tools-popper
          :content="$t('indicator.signal.warning')"
        >
          <a-icon
            name="connection-off"
            :width="16"
            :height="16"
            class="text--warning"
          />
        </meeting-tools-popper>
      </div>
      <a-icon
        v-if="app.state.matches('app.recording.on')"
        name="record"
        class="text--danger indicator__recording"
        :width="16"
        :height="16"
      />
    </div>

    <div class="indicator-lights" v-if="user.party === 'assistant' && (client.profile?.camera === 'muted' || client.profile?.camera === 'not-available' || cameraClientStatus )">
      <a-button
        :variant="`link-${client.profile?.camera === 'muted' ? 'default'  : 'danger'}`"
        :data-wml-speech-command="$t(`commands.camera${client.profile?.camera === 'on' ? 'On' : 'Off'}`)"
        data-test-id="camera"
      >
        <a-icon :name="`camera-off`" />
      </a-button>
    </div>
    <div class="indicator-lights" v-if="user.party === 'assistant' && (client.profile?.microphone === 'muted'||  client.profile?.microphone === 'not-available' )">
      <a-button
        :variant="`link-${client.profile?.microphone === 'muted' ? 'default'  : 'danger'}`"
        :data-wml-speech-command="$t(`commands.${client.profile?.microphone === 'on' ? 'mute' : 'unmute'}`)"
        data-test-id="microphone"
      >
        <a-icon :name="`microphone-off`" />
      </a-button>
    </div>
  </a-button-box>
  </div>

</template>


<script setup>
import {computed, onMounted, onUnmounted} from 'vue'
import { useAppStore, useSessionStore, useSignalStore,useUserStore } from '@/stores'
import AButtonBox from "@/components/AButtonBox.vue";
import AButton from "@/components/AButton.vue";
const user = useUserStore()

const app = useAppStore()
const session = useSessionStore()
const signal = useSignalStore()
const client = computed(()=>  session.getParticipant(1))
const cameraClientKey = computed(() => {
  let messageKey = null

  if (session.notClientCamera) {
    messageKey = 'clientWithoutCamera'
  } else if (session.notClientPresent) {
    messageKey = 'clientNotPresent'
  }

  return messageKey
})
const cameraClientStatus = computed(() => (session.notClientCamera || session.notClientPresent) && cameraClientKey.value)

onMounted(() => {
  session.startTimer()
})

onUnmounted(() => {
  session.stopTimer()
})

</script>

<style lang="scss">
.container{
  .indicator {
    .indicator-lights{
      display: flex;
      gap: 5px;
    }
    .indicator-inner{
      height: 42px;
      display: flex;
      gap: 10px;
      align-items: center;
    }
    &__recording {
      animation: 1s pulse linear infinite;
    }
    .a-divider {
      & ~ * {
        margin-left: map-get($spacers, 1);
      }
      &:last-child {
        display: none;
      }
    }
  }
}

</style>
