<template>
  <div class="chat">
    <div class="chat__header">
      <h2>Chat</h2>
      <a-button
        variant="reset" 
        @click="send('HIDE_CHAT')"
      >
        <a-icon name="close" />
      </a-button>
    </div>
    <div class="chat__content" ref="content">
      <meeting-chat-message
        v-for="message in chat.read"
        :key="message.timestamp"
        :message="message"
      />
      <div v-if="chat.read.length && chat.unread.length" class="chat__divider">New</div>
      <meeting-chat-message
        v-for="message in chat.unread"
        :key="message.timestamp"
        :message="message"
      />
    </div>
    <div class="chat__footer">
      <div class="chat__input">
        <textarea
          rows="1"
          placeholder="Type a new message"
          v-model="text"
          @keydown.enter.exact.prevent="sendHandler"
          @input="inputHandler"
        ></textarea>
        <a-button
          variant="link-primary" 
          @click="sendHandler"
          :disabled="!text.length"
        >
          <a-icon name="send" :width="20" :height="20" />
        </a-button>
      </div>
      <Transition name="slide-up-fade">
        <div v-if="recent" class="chat__recent">
          <a-button
            variant="center"
            @click="scrollContent"
          >
            <a-icon name="arrow-down" :width="20" :height="20" class="me--1" />
            New message
          </a-button>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import container from '@di'
import { useChatStore, useUserStore } from '@/stores'
import { useMeetingMachine } from '@/state'
import { sleep } from '@/utils'

const chat = useChatStore()
const user = useUserStore()
const { state, send, service } = useMeetingMachine()
const chatProcedure = container.procedures.get('chatProcedure')
const text = ref('')
const content = ref(null)
const inputHeight = ref('auto')
const recent = ref(false)

function sendHandler () {
  if (text.value.length) {
    const { id, fullname, email } = user
    const timestamp = new Date().toISOString()
    scrollContent()
    chatProcedure.send({
      text: text.value,
      id,
      fullname,
      email,
      timestamp
    })
    text.value = ''
    inputHeight.value = 'auto'
  }
}

function inputHandler (event) {
  inputHeight.value = `${event.target.scrollHeight}px`
}

function scrollContent () {
  content.value.scrollTop = content.value.scrollHeight
  recent.value = false
}

chat.$onAction(({ name, after }) => {
  if (name === 'addMessage') {
    const scrolled = content.value.scrollHeight !== content.value.offsetHeight + content.value.scrollTop
    if (!chat.unread.length && state.value.matches('chat.visible')) {
      chat.markRead()
    }
    after(async () => {
      if (scrolled) {
        recent.value = true
      } else {
        await sleep(100)
        scrollContent()
      }
    })
  }
})

service.subscribe(async (state) => {
  if (state.event.type === 'HIDE_CHAT') {
    chat.markRead()
  }
  if (state.event.type === 'SHOW_CHAT') {
    await sleep(100)
    scrollContent()
  }
})
</script>

<style lang="scss">
$chat-bg: $white;
$chat-width: 300px;
$chat-party-bg: $purple-200;
$chat-counterparty-bg: $gray-100;
$chat-message-spacer: $spacer;
$chat-message-radius: 1rem;
$chat-input-border-width: 1px;
$chat-input-border-color: $gray-200;
$chat-divider-color: $red-400;
$chat-divider-size: 1px;
$chat-info-color: $gray-300;
$chat-recent-bg: $backdrop;
$chat-recent-color: $white;
$chat-box-shadow: -0.25rem -0.125rem  0.75rem rgba($black, 0.1);
.chat {
  @include fill-height(max);
  display: flex;
  flex-direction: column;
  position: fixed;
  width: $chat-width;
  bottom: 0;
  right: 0;
  background-color: $chat-bg;
  box-shadow: $chat-box-shadow;
  border-top-left-radius: $border-radius;
  z-index: $zindex-chat;
  &__header,
  &__footer {
    padding: $box-spacer;
  }
  &__header {
    position: relative;
    .btn {
      position: absolute;
      top: $box-spacer;
      right: $box-spacer;
    }
  }
  &__footer {
    position: relative;
  }
  &__content {
    @include hide-scrollbar();
    flex-grow: 1;
    padding: 0 $box-spacer;
    overflow: auto;
  }
  &__message {
    margin: $chat-message-spacer 0;
    &.is--party {
      padding-left: ($chat-message-spacer * 3);
    }
    &.is--counterparty {
      padding-right: ($chat-message-spacer * 3);
    }
    &__bubble {
      white-space: pre-line;
      word-break: break-word;
      padding: $chat-message-spacer;
      border-radius: $chat-message-radius;
      flex-grow: 1;
      .is--party > & {
        background-color: $chat-party-bg;
        text-align: right;
        border-bottom-right-radius: 0;
      }
      .is--counterparty > & {
        background-color: $chat-counterparty-bg;
        border-bottom-left-radius: 0;
      }
    }
    &__info {
      font-size: $font-size-xs;
      color: $chat-info-color;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      .is--party > & {
        text-align: right;
      }
    }
  }
  &__input {
    position: relative;
    padding: $chat-message-spacer;
    padding-right: ($chat-message-spacer * 2.5);
    border: $chat-input-border-width solid $chat-input-border-color;
    border-radius: $border-radius;
    textarea {
      display: block;
      width: 100%;
      height: v-bind(inputHeight);
      margin: 0;
      padding: 0;
      border: 0;
      resize: none;
      &:focus {
        outline: none;
      }
    }
    .btn {
      position: absolute;
      top: ($chat-message-spacer * 0.5);
      right: ($chat-message-spacer * 0.5);
    }
  }
  &__divider {
    display: flex;
    align-items: center;
    color: $chat-divider-color;
    font-size: $font-size-xs;
    text-transform: uppercase;
    &::after {
      content: '';
      flex-grow: 1;
      height: $chat-divider-size;
      background: currentColor;
      margin-left: map-get($spacers, 2);
    }
  }
  &__recent {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 100%;
    padding: 0 $box-spacer;
    & > .btn {
      background-color: $chat-recent-bg;
      color: $chat-recent-color;
      width: 100%;
    }
  }
  &__picker {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
    margin: 0 -4px;
    .btn {
      padding: 4px;
    }
  }
  @media (max-height: $breakpoint-sm) {
    transition-property: transform, padding-bottom;
    &.expanded {
      padding-bottom: 0;
    }
  }
}
</style>
