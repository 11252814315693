<template>
  <div ref="rootEl">
    <meeting-tools-popper
      v-if="whitelist.includes('size')"
      v-show="board.selected.size"
      :content="$t('tools.stroke')"
    >
      <a-button
        variant="link-primary"
        :class="submenu === 'size' && 'is--selected'"
        value="size"
        corner
        @click="emits('submenu', $event)"
      >
        <a-icon
          name="circle-full"
          :scale="board.size / 24"
        />
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('color')"
      v-show="board.selected.color"
      :content="$t('tools.color')"
    >
      <a-button
        variant="link-primary"
        :class="submenu === 'color' && 'is--selected'"
        value="color"
        corner
        :style="{ color: board.color }"
        @click="emits('submenu', $event)"
      >
        <a-icon name="rect-full" />
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('delete')"
      :content="$t('tools.delete')"
    >
      <a-button
        variant="link-primary"
        @click="emits('delete')"
      >
        <a-icon name="delete" />
      </a-button>
    </meeting-tools-popper>
    <template v-if="whitelist.includes('shapes')">
      <meeting-tools-popper
        v-for="shape in board.shapes"
        :key="shape"
        :content="$t(`tools.shapes.${shape}`)"
      >
        <a-button
          variant="link-primary"
          :class="board.shape === shape && (['shaping', 'typing'].includes(board.task) || board.shape === 'freedraw') && 'is--active'"
          :value="shape"
          @click="emits('shape', $event)"
        >
          <a-icon :name="shape" />
        </a-button>
      </meeting-tools-popper>
    </template>
    <meeting-tools-popper
      v-if="whitelist.includes('dart')"
      :content="$t('tools.pointerTool')"
    >
      <a-button
        variant="link-primary"
        :class="board.task === 'darting' && 'is--active'"
        :disabled="session.notClientCamera && app.state.matches('app.view.meeting.call')"
        value="darting"
        @click="emits('task', $event)"
      >
        <a-icon name="arrow" />
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('pan')"
      :content="$t('tools.panningTool')"
    >
      <a-button
        variant="link-primary"
        :class="board.task === 'panning' && 'is--active'"
        :disabled="session.notClientCamera && app.state.matches('app.view.meeting.call')"
        value="panning"
        @click="emits('task', $event)"
      >
        <a-icon name="move" />      
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('select')"
      :content="$t('tools.selectionTool')"
    >
      <a-button
        variant="link-primary"
        :class="board.task === 'selecting' && 'is--active'"
        value="selecting"
        @click="emits('task', $event)"
      >
        <a-icon name="select" />      
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('save')"
      :content="$t('tools.saveSnapshot')"
    >
      <a-button
        variant="link-primary"
        @click="emits('saveSnapshot')"
      >
        <a-icon name="save" />
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('ocr') && session.info.allowOCR"
      :content="$t(`tools.${board.ocr ? 'discard' : 'recognize'}Text`)"
    >
      <a-button
        variant="link-primary"
        :disabled="!isSaveDone"
        @click="emits('ocr')"
      >
        <a-icon :name="`font${board.ocr ? '-off' : ''}`" />
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('snapshot')"
      :content="$t('tools.takeSnapshot')"
    >
      <a-button
        variant="link-primary"
        :disabled="session.notClientCamera"
        @click="emits('takeSnapshot')"
      >
        <a-icon name="photo" />      
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('upload')"
      :content="$t('tools.uploadImage')"
    >
      <a-button
        variant="link-primary"
        @click="emits('uploadImage')"
      >
        <a-icon name="image" />
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('share') && app.isScreensharingSupported && session.info.allowDesktop"
      :content="!app.state.matches('app.view.meeting.screensharing') ? $t('tools.startScreensharing') : $t('tools.stopScreensharing')"
    >
      <a-button
        variant="link-primary"
        @click="emits('screensharing')"
      >
        <a-icon :name="!app.state.matches('app.view.meeting.screensharing') ? 'screen' : 'screen-off'" />
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('record') && !app.state.matches('app.recording.recordingNotAllowed') && session.info.allowRecording"
      :content="app.state.matches('app.recording.off') ? $t('tools.startRecording') : $t('tools.stopRecording')"
    >
      <a-button
        variant="link-primary"
        :disabled="session.notClientCamera"
        @click="emits('recording')"
        :class="[
          app.state.matches('app.recording.on') && 'text--danger'
        ]"
      >
        <a-icon name="record" />
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('torch') && session.hasTorchCapability"
      :content="$t('tools.turnOnClientLight')">
      <a-button
        variant="link-primary"
        @click="emits('torch')"
      >
        <a-icon :name="session.torchStatus ? 'torch-off' : 'torch'" />      
      </a-button>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('chat')"
      :content="chat.preview"
      :show="chatPreview"
    >
      <meeting-tools-popper
        :content="$t('tools.chat')"
        :disabled="chatPreview"
      >
        <a-button
          variant="link-primary"
          :badge="state.matches('chat.hidden') && !!chat.unread.length"
          @click="emits('chat')"
        >
          <a-icon name="chat" />
        </a-button>
      </meeting-tools-popper>
    </meeting-tools-popper>
    <meeting-tools-popper
      v-if="whitelist.includes('settings')"
      :content="$t('tools.settings')"
    >
      <a-button
        variant="link-primary"
        @click="emits('settings')"
      >
        <a-icon name="settings" />      
      </a-button>
    </meeting-tools-popper>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue'
import {
  useAppStore,
  useUserStore,
  useSessionStore,
  useBoardStore,
  useSnapshotStore,
  useChatStore,
} from '@/stores'
import { useMeetingMachine, useAppMachine } from '@/state'

defineProps({
  submenu: String
})

const emits = defineEmits([
  'submenu',
  'delete',
  'shape',
  'task',
  'saveSnapshot',
  'takeSnapshot',
  'uploadImage',
  'screensharing',
  'recording',
  'ocr',
  'torch',
  'chat',
  'settings'
]) 

const app = useAppStore()
const user = useUserStore()
const session = useSessionStore()
const board = useBoardStore()
const snapshot = useSnapshotStore()
const chat = useChatStore()

const { state } = useMeetingMachine()
const { state: appState, service } = useAppMachine()

const rootEl = ref(null)
const whitelist = ref([])
const chatPreview = ref(false)
let chatPreviewTimer = 0
const isSaveDone = ref(false)

watch(snapshot, (val) => {
  isSaveDone.value = val.takePictureTouch.isSaveDone
})

defineExpose({ rootEl })

const updateTools = () => {
  let tools = []
  // size, color, delete, shapes, freedraw, rect, circle, text, dart, pan, select, save, ocr, snapshot, upload, share, record, torch, chat, settings
  let { meeting } = appState.value.value.app.view
  if (typeof meeting === 'object') {
    meeting = Object.keys(meeting).map((key) => `${key}.${meeting[key]}`).join('')
  }
  switch (meeting) {
    case 'lobby': {
      tools = [
        'chat',
        'settings'
      ]
      break
    }
    case 'call': {
      tools = [
        'chat',
        'settings',
        ...(user.isAssistant ? [
          'dart',
          'pan',
          'snapshot',
          'upload',
          'share',
          'record',
          'torch'
        ] : [])
      ]
      break
    }
    case 'screensharing': {
      tools = [
        'chat',
        'settings',
        ...(user.isAssistant ? [
          'share'
        ] : [])
      ]
      break
    }
    case 'draw.default': {
      tools = [
        'chat',
        'settings',
        ...(user.isAssistant ? [
          'size',
          'color',
          'shapes',
          'dart',
          'pan',
          'select',
          'save',
          'ocr'
        ] : [])
      ]
      break
    }
    case 'draw.select': {
      tools = [
        ...(user.isAssistant ? [
          'size',
          'color',
          'delete'
        ] : [])
      ]
      break
    }
  }
  whitelist.value = tools
}

watch(() => user.isAssistant, () => {
  updateTools()
})

service.subscribe(() => {
  updateTools()
})

chat.$onAction(({ name, after }) => {
  if (name === 'previewMessage') {
    after(() => {
      if (state.value.matches('chat.hidden')) {
        chatPreview.value = true
        clearTimeout(chatPreviewTimer)
        chatPreviewTimer = setTimeout(() => {
          chatPreview.value = false
        }, 3000)
      }
    })
  }
})
</script>
