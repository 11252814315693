import container from '@di'
import { useSessionStore, useAppStore } from '@/stores'

export default class ParticipantDisjoinedProcedure {
  constructor () {
    this.name = 'participantDisjoinedProcedure'
  }

  install = () => {
    container.messenger.subscribe(this.name, 'session:participant:disjoined', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (event) => {
    const { id: participantId } = event
    const session = useSessionStore()
    const participant = session.getParticipant(participantId)
    const app = useAppStore();

    if(participant){
      if (participant.party === 'client' && !session.sessionFailed) {
        app.transition('lobby')
        session.removeParticipant(participantId)
      } else if (participant.party === 'assistant' && !session.sessionFailed) {
        app.transition('exit')
        session.removeParticipant(participantId)
      } else {
        session.removeParticipant(participantId)
      }
    }
  }
}
