import container from '@di'
import { useSessionStore, useAppStore, useAnnouncementStore } from '@/stores'

export default class ParticipantDisconnectedProcedure {
  constructor () {
    this.name = 'participantDisconnectedProcedure'
  }

  install = () => {
    container.messenger.subscribe(this.name, 'websocket:participant:disconnected', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (event) => {
    const { id: participantId } = event
    const session = useSessionStore()
    const participant = session.getParticipant(participantId)

    if (participant.party === 'client' || participant.party === 'assistant') {
      const announcement = useAnnouncementStore()
      const app = useAppStore()
      if (app.state.matches('app.view.meeting.draw')) {
        container.messenger.emit('board:signal', 'snapshot:save')
        container.procedures.get('snapshotProcedure').dismiss('snapshot')
      }
      announcement.hideMessage()
      app.transition('lobby')
      session.removeParticipant(participantId)
    } else {
      session.removeParticipant(participantId)
    }
   }
}
