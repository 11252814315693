import { ref, shallowReactive } from 'vue'
import { defineStore } from 'pinia'

export default defineStore('snapshot', () => {
  const key = ref('')
  const takePictureTouch = shallowReactive({
    isSaveDone: false,
    isUpload: false,
  })
  const transform = shallowReactive({
    scale: 1,
    left: 0,
    top: 0,
    width: 0,
    height: 0
  })

  function setTakePictureTouch (takePictureTouch) {
    this.$patch({ takePictureTouch })
  }

  function setKey (key) {
    this.$patch({ key })
  }

  function setTransform (transform) {
    this.$patch({ transform })
  }

  return {
    key,
    takePictureTouch,
    transform,
    setKey,
    setTakePictureTouch,
    setTransform
  }
})
